
require('waypoints/lib/noframework.waypoints.js');
require('headroom.js/dist/headroom.min.js');


// HEADROOM
import Headroom from "headroom.js/dist/headroom.min.js";
const header = document.querySelector(".c-header__wrapper");
var options = {
    offset : 39,
}

const headroom = new Headroom(header, options);
headroom.init();

// NAV MOBILE TOGGLE
let navToggle = document.querySelector('.js-nav-toggle');
let menuWrapper = document.querySelector('.c-nav--primary__wrapper');

navToggle.addEventListener('click', function () {

  if (navToggle.classList.contains('utl-is-active')) {
    navToggle.classList.remove('utl-is-active');
    menuWrapper.classList.remove('utl-is-active');
    document.body.classList.remove("utl-menu-open");
    this.setAttribute('aria-expanded', 'false');

  } else {
    navToggle.classList.add('utl-is-active');
    menuWrapper.classList.add('utl-is-active');
    document.body.classList.add("utl-menu-open");
    this.setAttribute('aria-expanded', 'true');
  }
});

// JS TOGGLE/EXPAND
const toggles = document.querySelectorAll('.js-toggle');

for (let a of toggles) {
    a.addEventListener('click', function (event) {

      if (a.classList.contains('utl-is-expanded')) {
        a.classList.remove('utl-is-expanded');
        a.setAttribute('aria-expanded', 'false');
        event.preventDefault();

      } else {
        a.classList.add('utl-is-expanded');
        a.setAttribute('aria-expanded', 'true');
        event.preventDefault();
      }
    });
}

// JS ANIMATE
var elements = document.getElementsByClassName('js-animate');

for (var i = 0; i < elements.length; i++) {
    var element = elements[i];
    element.className += ' js-animate--pending';
}

var continuousElements = document.getElementsByClassName('js-animate')
for (var i = 0; i < continuousElements.length; i++) {
  new Waypoint({
    element: continuousElements[i],
    handler: function() {
      this.element.classList.add("js-animate--start")
  },
    offset: '97%'
  })
}

// LAZY LOAD
var lazyLoadInstance = new LazyLoad({
    elements_selector: ".js-lazy"
});

// VIEWPORT CHECK
var isOutOfViewport = function(elem) {
    var bounding = elem.getBoundingClientRect();

    // Check if it's out of the viewport on each side
    var out = {};
    out.top = bounding.top < 0;
    out.left = bounding.left < 0;
    out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
    out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
    out.any = out.top || out.left || out.bottom || out.right;
    out.all = out.top && out.left && out.bottom && out.right;

    return out;

    // how to use:
    // var elem = document.querySelector('#some-element');
    // var isOut = isOutOfViewport(elem);
    // if (isOut.top) { 'Top is out of viewport' }
    // if isOut options top, bottom, left, right, any, all
};


// YOUTUBE EMBEDS
( function() {

    var youtube = document.querySelectorAll( ".b-video__youtube-wrapper" );

    for (var i = 0; i < youtube.length; i++) {

        var source = "https://img.youtube.com/vi/"+ youtube[i].dataset.embed +"/0.jpg";

        var image = new Image();
                image.src = source;
                image.addEventListener( "load", function() {
                    youtube[ i ].appendChild( image );
                }( i ) );

                youtube[i].addEventListener( "click", function() {

                    var iframe = document.createElement( "iframe" );

                            iframe.setAttribute( "frameborder", "0" );
                            iframe.setAttribute( "allowfullscreen", "" );
                            iframe.setAttribute( "src", "https://www.youtube.com/embed/"+ this.dataset.embed +"?rel=0&showinfo=0&autoplay=1" );

                            this.innerHTML = "";
                            this.appendChild( iframe );
                } );
    };

} )();



// popup Modal
var modal = document.getElementById("popUp");
var btn = document.getElementById("enquireNow");
var span = document.getElementsByClassName("close")[0];

// When the user clicks on the button, open the modal
if ( modal ) {
    btn.onclick = function() {
        modal.style.display = "flex";
    }

    // When the user clicks on <span> (x), close the modal
    span.onclick = function() {
        modal.style.display = "none";
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
        if (event.target == modal) {
            modal.style.display = "none";
        }
    }

    let form = modal.querySelector('form');
    if ( form ) {
        let submit = form.querySelector('button[type=submit]')

        if( submit ) {
            submit.addEventListener('click', function() {
                modal.scrollTo({
                   top: 0,
                   behavior: "smooth"
               });
            });
        }

        // let heading = document.querySelector('.m-product__content__heading').textContent;
        // let size = document.querySelector('.select-selected > .fitem-0').textContent;
        // let productName = document.querySelector('input[name=product]');
        // let sizeField = document.querySelector('input[name=productSize]');
        //
        // if( productName && heading) {
        //     productName.value = heading;
        // }
        //
        // if( sizeField && size ) {
        //     sizeField.value = size;
        // }
    }
}

